import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-provo-utah.png'
import image0 from "../../images/cities/scale-model-of-provo-pioneer-village-in-provo-utah.png"
import image1 from "../../images/cities/scale-model-of-utah-lake-state-park-in-provo-utah.png"
import image2 from "../../images/cities/scale-model-of-byu-museum-of-paleontology-in-provo-utah.png"
import image3 from "../../images/cities/scale-model-of-escape-gate-in-provo-utah.png"
import image4 from "../../images/cities/scale-model-of-alma's-lds-tours-llc-in-provo-utah.png"
import image5 from "../../images/cities/scale-model-of-morris-murdock-travel-of-orem-in-provo-utah.png"
import image6 from "../../images/cities/scale-model-of-gordon-b.-hinckley-alumni-and-visitors-center-in-provo-utah.png"
import image7 from "../../images/cities/scale-model-of-book-of-mormon-tours-in-provo-utah.png"
import image8 from "../../images/cities/scale-model-of-utah-valley-convention-and-visitors-bureau-in-provo-utah.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Provo'
            state='Utah'
            image={image}
            lat='40.2453'
            lon='-111.6448'
            attractions={ [{"name": "Provo Pioneer Village", "vicinity": "500 W 600 N, Provo", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.24206420000001, "lng": -111.6667711}, {"name": "Utah Lake State Park", "vicinity": "4400 W Center St, Provo", "types": ["park", "point_of_interest", "establishment"], "lat": 40.2378421, "lng": -111.73621630000002}, {"name": "BYU Museum of Paleontology", "vicinity": "84602-3300, 1683 N Canyon Rd, Provo", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.2565089, "lng": -111.6569867}, {"name": "Escape Gate", "vicinity": "205 W 520 N, Orem", "types": ["point_of_interest", "establishment"], "lat": 40.3066939, "lng": -111.7007092}, {"name": "Alma's LDS Tours LLC", "vicinity": "742 N 500 W #204, Provo", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.243611, "lng": -111.667394}, {"name": "Morris Murdock Travel of Orem", "vicinity": "1431 State St, Orem", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.27122300000001, "lng": -111.68224700000002}, {"name": "Gordon B. Hinckley Alumni and Visitors Center", "vicinity": "Brigham Young University, 450 E 1230 N St, Provo", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.2517266, "lng": -111.65165300000001}, {"name": "Book of Mormon Tours", "vicinity": "275 N 1000 E, Orem", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.3019948, "lng": -111.67211889999999}, {"name": "Utah Valley Convention and Visitors Bureau", "vicinity": "220 W Center St #100, Provo", "types": ["point_of_interest", "establishment"], "lat": 40.23412889999999, "lng": -111.6625874}] }
            attractionImages={ {"Provo Pioneer Village":image0,"Utah Lake State Park":image1,"BYU Museum of Paleontology":image2,"Escape Gate":image3,"Alma's LDS Tours LLC":image4,"Morris Murdock Travel of Orem":image5,"Gordon B. Hinckley Alumni and Visitors Center":image6,"Book of Mormon Tours":image7,"Utah Valley Convention and Visitors Bureau":image8,} }
       />);
  }
}